import React from "react"
import {Paper} from "@material-ui/core";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    fromCont: {
        margin: theme.spacing(3),
        backgroundColor: "lightblue"
    },
    toCont: {
        margin: theme.spacing(3),
        backgroundColor: "lightgreen"
    },
    from:{
        textAlign:"end",
    },
    to:{
        textAlign: "start",
    }

}));

const AtCommandLogs = ( props : any) => {
    const [logFiles, setLogFiles] = React.useState<string[]>([]);
    const [currentFile, setCurrentFile] = React.useState<string|undefined>(undefined);
    const [fileContent, setFileContent]  = React.useState<any[]>([]);
    //const urlBase: String = "http://localhost:8080/api/";
    const urlBase: String ="/api/";
    React.useEffect(() => {
        axios.get(urlBase + "logfiles/").then(res => {
            const files = res.data;
            setLogFiles(["",...files])
        })
    }, []);
    const classes = useStyles();
    const changeSelectedFile = (e : any) => {
        const value : string = e.currentTarget.value;
        setCurrentFile(value);
        if(value.length === 0){
            setFileContent([]);
        } else {
            axios.get(urlBase + "getLogFileContent", {params:{filename:value}}).then(res => {
                const filecontent = res.data;
                setFileContent(filecontent);
            })
        }

    };
    return <div style={{margin: "30px", display: "flex", flexDirection: "row", width: "100%"}}>
        <Paper style={{display: "flex", flexDirection: "column"}} elevation={5}>
            <div style={{ padding: "10px"}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                    <p> Fichier de logs : </p>
                    <select value={currentFile} onChange={changeSelectedFile}>
                        {
                            logFiles.map((f,i) => <option key={i} value={f}>{f}</option>)
                        }
                    </select>
                </div>
            </div>
        </Paper>
        <Paper style={{display: "flex", width: "80%", marginLeft: "30px", padding:"16px", flexDirection: "column"}} elevation={5}>
            {fileContent.map((msg, i) => {
                const c = msg.isFromRemote?classes.from:classes.to;
                const c2 = msg.isFromRemote?classes.fromCont:classes.toCont;
                return <Paper  style={{display: "flex", flexDirection: "column", padding:"10px"}} key={i} className={c2}>
                    <div style={{textAlign:"center"}}>
                        {msg.mDate}
                    </div>
                    <div className={c}>
                    {msg.msgTxt}
                    </div>
                </Paper>
            })}
        </Paper>
    </div>
};

export default AtCommandLogs;