import React from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.css';
import ActivityLogs from "./components/ActivityLogs";
import { Route } from "react-router-dom";
import Login from "./components/Login";
import SimpleAppBar from "./components/SimpleAppBar";
import AtCommandLogs from "./components/AtCommandLogs";
import Crashes from "./components/Crashes";
import GpsNetwork from "./components/GpsNetwork"



function App() {
    const [logged, setLogged] =  React.useState(false);
    if(logged){
        return <main>
            <BrowserRouter>
                <SimpleAppBar setLogged={setLogged}/>
                <Route key={1} path={"/"} exact component={ActivityLogs} />
                <Route key={2} path={"/atlogs"} exact component={AtCommandLogs} />
                <Route key={3} path={"/crashs"} exact component={Crashes} />
                <Route key={4} path={"/gps"} exact component={GpsNetwork} />
            </BrowserRouter>
        </main>
    } else
        return <Login setLogged={setLogged}/>;

}

export default App;
