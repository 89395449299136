import React from "react";
import axios from "axios";
import {Paper} from "@material-ui/core";

import { CSVLink } from "react-csv";

interface IDateSelector {
    setDate: (d: Date) => void,
    date: Date
}

export const DateSelector = ({setDate, date}: IDateSelector) => {
    const handleChange = (e: any) => {
        console.log(e.currentTarget.value);
        setDate(new Date(e.currentTarget.value));
    };
    return <input contentEditable={false} type={"date"} onChange={handleChange} value={date.toISOString().substring(0, 10)}/>
};

const Localisation = ({row} : {row: any}) => {
    const {mPosition} = row;
    let lineOne = "";
    let lineTwo = "";

    if(mPosition && mPosition.length >0){
        const infos = mPosition.split("/")
        if(infos.length){
            if(infos.length > 1) {
                if(infos[1].length > 2)
                    lineTwo = infos[1];
            }
            if(infos[0].length > 2)
                lineOne = infos[0];
        }
    }

    return <td style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <td  style={{display:"flex", flexDirection:"column"}}>
            <td>{lineOne}</td>
            <td>{lineTwo}</td>
        </td>
        <td style={{display:"flex", flexDirection:"column"}}>
            {(row && row.mLon && row.mLat) && <div>Lat :{row.mLat}</div>}
            {(row && row.mLon && row.mLat) && <div>Lon : {row.mLon}</div>}
        </td>
    </td>;
}

const ActivityLogs = () => {

    const startDelta : number = 24 * 60 * 60 * 1000*30;
    const [start, setStart] = React.useState<Date>(new Date(new Date().getTime() - startDelta));
    const [end, setEnd] = React.useState<Date>(new Date());
    const [users, setUsers] = React.useState<String[]>(["Tous"]);
    const [currentUser, setCurrentUser] = React.useState<number>(0);
    const [data, setData] = React.useState<any[]>([]);

    //const urlBase: String = "http://localhost:8080/api/";
    //const urlBase: String = "http://siemacloud.com:8080/api/";
    let urlBase: String ="/api/";
    //if(window.location.port === "3000")
    //    urlBase = "http://localhost:8080/api/";
    const changeUser = (e: any) => {
        setCurrentUser(parseInt(e.currentTarget.value));
    };

    React.useEffect(() => {
        axios.get(urlBase + "users/").then(res => {
            const usrs = res.data;
            usrs.push("Tous");
            setUsers(usrs);
            setCurrentUser(usrs.length-1);
        })
    }, []);
    React.useEffect(() => {
        if(isNaN(start.getTime())){
            return;
        }
        console.log("Fetching");
        axios.get(urlBase + "data/", {
            params:
                {
                    start,
                    end,
                    user: users[currentUser]
                }
        }).then(res => {
            console.log(res.data);
            let theData = res.data.map((line: any) => Object.assign({},line, {mDate : new Date(line.mDate)}));
            //setData(res.data.filter((line : any) => line.mDate >= start && line.mDate <= end));
            //theData = theData.sort((l1 : any, l2: any) => (l2.mDate.getTime() -l1.mDate.getTime()))
            setData(theData);
        })
    }, [start, end, currentUser, users]);
    let fileName = "ExportActiviteSoprano_";
    fileName += new Date().toISOString().substring(0,16).replace(":","_").replace("-","_");
    fileName += ".csv";
    return (
        <div className="App">
            <CSVLink  filename={fileName} separator={";"} data={data}>Télécharger en CSV</CSVLink>
            <div style={{margin: "30px", display: "flex", flexDirection: "row", width: "100%"}}>
                <Paper style={{display: "flex", flexDirection: "column"}} elevation={5}>
                    <div style={{ padding: "10px"}}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                            <p style={{marginRight: "10px"}}> A partir du : </p>
                            <DateSelector setDate={setStart} date={start}/>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                            <p> Jusqu'au : </p>
                            <DateSelector setDate={setEnd} date={end}/>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                            <p> Utilisateur : </p>
                            <select value={currentUser} onChange={changeUser}>
                                {
                                    users.map((u, i) => <option key={i} value={i}>{u}</option>)
                                }
                            </select>
                        </div>
                    </div>
                </Paper>
                <Paper style={{display: "flex", width: "80%", marginLeft: "30px", padding:"16px"}} elevation={5}>
                    <table style={{width: "100%", textAlign: "left"}}>
                        <thead>
                        <tr>
                            <th>Utilisateur</th>
                            <th>Date</th>
                            <th>Type evt</th>
                            <th> Détail</th>
                            <th>Position</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((row, index) => <tr  key={index}>
                            <td>{row.mFuncNum}</td>
                            <td>{row.mDate.toLocaleString()}</td>
                            <td>{row.mType}</td>
                            <td>{row.mDescription}</td>
                            <td><Localisation row={row}/></td>
                        </tr>)}
                        </tbody>
                    </table>

                </Paper>

            </div>
            <div>

            </div>
        </div>
    )
};

export  default ActivityLogs;