import * as React from "react"
import axios from "axios";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {DateSelector} from "./ActivityLogs";
import {makeStyles} from "@material-ui/core/styles";

interface IGpsNetworkPoint {
    mDate: Date,
    mLat: number,
    mLon: number,
    mValues : string[]
}
const useStyles = makeStyles(theme => ({
    root: {},
    borderAllButLeft: {
        borderWidth: 1,
        borderLeftWidth: 0,
        borderColor: '#AAA6',
        borderStyle: 'solid',
    },
    borderRightBottom: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#AAA6',
        borderStyle: 'solid',
    },
    tableRightAndUp:{
        borderWidth: 0,
        borderTopWidth : 1,
        borderRightWidth : 1,
        borderColor: '#AAA6',
        borderStyle: 'solid',
    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth : 1,
        borderColor: '#AAA6',
        borderStyle: 'solid',
    },
    noBorder : {
        borderWidth: 0,
    }
}));

const globalFields = ["mDate", "mLat", "mLon", "mIMEI"];
const MainCellfields = ["MCC","MNC","LAC","cell","BSIC","chann","RxLev","RxLev Full", "RxLev Sub", "RxQual", "RxQual Full","RxQual Sub","Timeslot"];
const OtherCellFields = MainCellfields.filter((f,i) => i<7);
const GpsNetwork = () => {
    const [start, setStart] = React.useState<Date>(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
    const [end, setEnd] = React.useState<Date>(new Date());
    const [points, setpoints] = React.useState<IGpsNetworkPoint[]>([]);
    const classes = useStyles();

    //const urlBase: String = "http://localhost:8080/api/";
    //const urlBase: String = "http://siemacloud.com:8080/api/";
    const urlBase: String ="/api/";
    React.useEffect(() => {
        axios.get(urlBase + "gpsNetwork/").then(res => {
            console.log(res.data);
            res.data.forEach((p:any) => p.mDate = new Date(p.mDate));
            setpoints(res.data);
        })
    }, []);
    const getOtherCells = (point : IGpsNetworkPoint) => {
        let index = MainCellfields.length;
        const stuff = [];
        while((point.mValues.length -3)  > (index + OtherCellFields.length)){

            let extraCols = [];
            for(let i = 0; i <= (MainCellfields.length- OtherCellFields.length); ++i)
                extraCols.push(<TableCell  className={classes.tableRightBorder}/>);
            stuff.push(<TableRow key={"k"+index}>
                <TableCell className={classes.tableRightBorder}/>
                <TableCell className={classes.tableRightBorder}/>
                <TableCell className={classes.tableRightBorder}/>
                <TableCell className={classes.tableRightBorder}/>
            { OtherCellFields.map((f,i) => <TableCell className={classes.borderRightBottom} key={i+index}>{point.mValues[i+index]}</TableCell>)}
                {extraCols}

            </TableRow>);

            index += OtherCellFields.length
        }
        return stuff;
    };
    const showPoints = points.filter(p => ((p.mDate >= start) && (p.mDate <= end))).sort((p1,p2) => p2.mDate.getTime()-p1.mDate.getTime());

    return <div>
        <Paper style={{display: "flex", flexDirection: "column", width:"30%", margin:"15px"}} elevation={5}>
            <div style={{ padding: "10px"}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                    <p style={{marginRight: "10px"}}> A partir du : </p>
                    <DateSelector setDate={setStart} date={start}/>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                    <p> Jusqu'au : </p>
                    <DateSelector setDate={setEnd} date={end}/>
                </div>
            </div>
        </Paper>
        <Paper style={{display: "flex", flexDirection: "column", width:"90%", margin:"15px"}} elevation={5}>
        <Table stickyHeader>
            <TableHead key={"head"}>
                <TableRow>
                    {globalFields.map( f => <TableCell key={f}>{f}</TableCell>)}
                    {MainCellfields.map( f => <TableCell key={f}>{f}</TableCell>)}
                    <TableCell>rssi</TableCell>
                    <TableCell>berr</TableCell>
                </TableRow>
            </TableHead>
            <TableBody key={"bod"}>
                {showPoints.map( (point : IGpsNetworkPoint, thatIndex)=> {
                    console.log("handling point", point);
                    return [<TableRow key={thatIndex}>
                        {globalFields.map((f,i) => {

                            if(f==="mDate")
                                return <TableCell className={classes.tableRightAndUp} key={i}>{(point as any)[f].toISOString()}</TableCell>
                            else
                                return <TableCell className={classes.tableRightAndUp} key={i}>{(point as any)[f]}</TableCell>

                        })}
                        { MainCellfields.map((f,i) => <TableCell className={(i<7)?classes.borderRightBottom:classes.borderAllButLeft} key={i+100}>{point.mValues[i]}</TableCell>)}
                        <TableCell className={classes.borderAllButLeft} key={"rssi"}>{point.mValues[point.mValues.length-2]}</TableCell>
                        <TableCell className={classes.borderAllButLeft} key={"berr"}>{point.mValues[point.mValues.length-1]}</TableCell>
                    </TableRow>,
                    ...getOtherCells(point)
                ]

                })}

            </TableBody>
        </Table>
        </Paper>
    </div>
};

export default GpsNetwork;