import * as React from "react";
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from "@material-ui/core";


import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    buttonDiv: {
        height: "50px",
        margin: "auto",
        marginBottom: "10%",
        marginTop: "10%"
    },
    margin: {
        margin: theme.spacing(1)
    }
}));

const Login = (props: any) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {setLogged} = props;
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const classes = useStyles();

    const handleLogin = () => {
        setLoggingIn(true);

        axios.post("/api/login/", { username, password })
            .then((res:any)=>{               
                axios.defaults.headers.common['authorization'] = res.data.token;
                setLogged(true);
            })
            .catch(err => {
                if (err.response) {
                    const error = err.response.data.error;
                    const value = err.response.data.value;
                    switch (err.response.status) {
                        case 401:
                            setError('error.' + error);
                            break;
                        case 403:
                            if (value !== undefined && value !== -1) {
                                setError('error.' + error);
                            } else {
                                setError('error.' + error);
                            }
                            break;
                        default:
                            setError('error.' + error);
                            console.error("err : " + error, value)
                    }
                } else {
                    setError('Le serveur ne répond pas');
                }
            })
            .finally(() => {
                setLoggingIn(false);
            });
    };

    const renderSpinner = (): JSX.Element | null => {
        if (loggingIn) {
            return <CircularProgress />;
        } else {

            return (
                <Button
                    type="submit"
                    onClick={handleLogin}
                    variant="contained"
                    color="primary">
                    {"Connexion"}
                </Button>
            );
        }
    };

    const handleUsernameChange = (event: any) => {
        setUsername(event.target.value);
        setError("");
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
        setError("");
    };


    return (
        <Dialog open={true} fullWidth={true}>
            <div>
                <form className={classes.form} noValidate onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin()
                }}>
                    <h2>{"LOGIN"}</h2>



                    <div className={classes.margin}>
                        <Grid container spacing={1} alignItems="flex-end">

                            <Grid item>
                                <TextField
                                    onChange={handleUsernameChange}
                                    id="username"
                                    type="text"
                                    label={"login"}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.margin}>
                        <Grid container spacing={1} alignItems="flex-end">

                            <Grid item>
                                <TextField
                                    onChange={handlePasswordChange}
                                    id="password"
                                    type="password"
                                    helperText={error}
                                    label={"Mot de passe"}
                                    error={error.length > 0}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.buttonDiv}>
                        {renderSpinner()}
                    </div>
                </form>
            </div>

        </Dialog>
    );
};
export default Login;
