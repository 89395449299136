import {AppBar, Button, createStyles, IconButton, Theme, Toolbar, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        link:{
            color:"white",
            margin:theme.spacing(2)
        }
    }),
);
const SimpleAppBar = (props:any) => {
    const classes = useStyles();
    return <AppBar position="static">
        <Toolbar>

            <Link to={"/"} key={1}>
                <Typography variant="h6" className={classes.link}>
                    Activité
                </Typography>
            </Link>
            <Link to={"/atlogs"} key={2}>
                <Typography variant="h6" className={classes.link}>
                    AT commandes
                </Typography>
            </Link>
            <Link to={"/crashs"} key={3}>
                <Typography variant="h6" className={classes.link}>
                    Crash Reports
                </Typography>
            </Link>
            <Link to={"/gps"} key={4}>
                <Typography variant="h6" className={classes.link}>
                    Gps / Network
                </Typography>
            </Link>

            <div className={classes.title}>
            </div>
            <Button color="inherit" onClick={() => props.setLogged(false)}>Déconnexion</Button>
        </Toolbar>
    </AppBar>;
};
export default SimpleAppBar;
